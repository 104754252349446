exports.components = {
  "component---src-pages-dansstilar-js": () => import("./../../../src/pages/dansstilar.js" /* webpackChunkName: "component---src-pages-dansstilar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nyheter-js": () => import("./../../../src/pages/nyheter.js" /* webpackChunkName: "component---src-pages-nyheter-js" */),
  "component---src-pages-omoss-js": () => import("./../../../src/pages/omoss.js" /* webpackChunkName: "component---src-pages-omoss-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-varalarare-js": () => import("./../../../src/pages/varalarare.js" /* webpackChunkName: "component---src-pages-varalarare-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-larare-post-js": () => import("./../../../src/templates/larare-post.js" /* webpackChunkName: "component---src-templates-larare-post-js" */),
  "component---src-templates-nyheter-post-js": () => import("./../../../src/templates/nyheter-post.js" /* webpackChunkName: "component---src-templates-nyheter-post-js" */)
}

